import dayjs from 'dayjs'

/**
 * 日期格式转换
 * @param {String} value
 * @returns {String}
 */

export function dateFormat(value, format){
    if(!value) return ''
    if(!format) format = 'YYYY-MM-DD HH:mm:ss'
    let str = dayjs(value).format(format)
    return str
}