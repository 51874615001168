import store from '../store'
import axios from 'axios'
import qs from 'qs'
import { MessageBox } from 'element-ui'
import router from '../router/index'

let alertFn = function(msg){
    MessageBox.alert(msg, '提示', {
		confirmButtonText: '确定',
        type: 'warning'
	});
}

// api地址
let baseURL = 'https://k.xdhr1.com'

axios.defaults.baseURL = baseURL

// 请求超时时间
axios.defaults.timeout = 30 * 1000

// 跨域请求时是否需要使用凭证
// axios.defaults.withCredentials = true

// 设置post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 添加请求拦截器
axios.interceptors.request.use(config => {
    
    // 需要带token的请求
    config.headers.Authorization = store.state.token || 'empty-token'

    if (config.method === 'post') {
        if (config.dataType !== "json") {
            config.data = qs.stringify(config.data)
        }
    }
    return config
}, error => {
    Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {            
            return Promise.reject(response)      
        }
    }, error => {
        return Promise.reject(error)
    });

//请求成功处理
const httpSuccessFn = function(resolve, res, reject){
    switch(res.data.code) {
        case 0:
            resolve(res.data)
            break
        case 401:
            store.commit('LOGIN_OUT')
            router.replace({ name: 'login' })
            break
        case 500:
            reject()
            alertFn(res.data.msg)
            break
        default:
            alertFn(res.data.msg)
            break
    }
}

const http = {
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    get(url, params){    
        return new Promise((resolve, reject) =>{        
            axios.get(url, {            
                params: params        
            })
            .then(res => {
                httpSuccessFn(resolve, res, reject)
            })
            .catch(err =>{
                if(err.status == 404){
                    alertFn('请求的资源不存在')
                    return
                }
                alertFn('请求错误，请稍后重试')
                // reject(err.data)
            })    
        });
    },

    /** 
     * post方法，对应post请求 
     * @param {String} url [请求的url地址] 
     * @param {Object} data [请求时携带的参数] 
     * @param {Object} config [请求时携带的配置]
     */
    post(url, data, config = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, data, config)
            .then(res => {
                httpSuccessFn(resolve, res, reject)
            })
            .catch(err =>{
                if(err.status == 404){
                    alertFn('请求的资源不存在')
                    return
                }
                alertFn('请求错误，请稍后重试')
                reject(err)
            })
        })
    }
}

export default http