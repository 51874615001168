import Cookies from 'js-cookie'

export function getToken() {
  return Cookies.get('token')
}

export function setToken(token) {
  return Cookies.set('token', token)
}

export function removeToken() {
  return Cookies.remove('token')
}

export function getStorage(key) {
  let values = window.localStorage.getItem(key);
  if (!values) return ''
  try {
    return JSON.parse(values, null)
  } catch (e) {
    return values
  }
}

export function setStorage(key, value) {
  if (typeof value == "string") {
    window.localStorage.setItem(key, value)
    return
  }
  try {
      window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
      console.log(e)
  }
}