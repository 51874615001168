import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import home from './home'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 消除重复进入相同路由的警报
// push方式
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// replace方式
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [...home]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
    NProgress.start()
	if(to.name != 'login') {
		if(!store.state.token) {
			next('login')
			return
		} else if(!store.state.userInfo || !store.state.userInfo.id) {
			await store.dispatch('GET_USER_INFO')
			next()
			return
		}
	}
	if(to.meta.title)
		document.title = to.meta.title
	
	next()
})

router.afterEach((to, from, next) => {
	NProgress.done()
})

export default router
