import Vue from 'vue'
const _this = new Vue()

export default {
    SET_USER_INFO({commit, state}, userInfo) {
        commit('SET_USER_INFO', userInfo)
    },

    async GET_USER_INFO({commit, state}) {
        await _this.$https.get(_this.$api.getByToken)
                .then((res) => {
                    commit('SET_USER_INFO', res.data)
                })
    },

    GET_CART_TOTAL({commit, state}) {
        _this.$https.get(_this.$api.cartTotal,{
            centreId: state.userInfo.centreId,
            memberId: state.userInfo.id
        })
        .then((res) => {
            commit('SET_CART_TOTAL', res.data)
        })
    }
}