import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import https from './utils/https'
import dayjs from 'dayjs'
import './element/index'
import 'normalize.css'
import './assets/scss/variables.scss'
import './assets/scss/base.scss'
import './assets/scss/reset-element.scss'
import * as filters from './filters'

Object.keys(filters).forEach(key => {
 Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$https = https
Vue.prototype.$dayjs = dayjs

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
