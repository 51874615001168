<template>
  <div id="app">
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
	import Footer from '@/components/footer'
	
	export default {
		data() {
			return {

			}
		},

		components: {
			Footer
		}
	}
</script>

<style lang='scss' scoped>
	#app{
		display: flex;
		flex: 1;
		flex-direction: column;
	}
</style>